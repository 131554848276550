@font-face {
  font-family: 'icomoon';
  src:  url('./icomoon.eot?qpn7g3');
  src:  url('./icomoon.eot?qpn7g3#iefix') format('embedded-opentype'),
    url('./icomoon.ttf?qpn7g3') format('truetype'),
    url('./icomoon.woff?qpn7g3') format('woff'),
    url('./icomoon.woff2?qpn7g3') format('woff'),
    url('./icomoon.svg?qpn7g3#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.icon-alert-circle:before {
  content: "\e900";
}
.icon-alert-triangle:before {
  content: "\e901";
}
.icon-bar-chart:before {
  content: "\e902";
}
.icon-video:before {
  content: "\e903";
}
.icon-arrow-up-right:before {
  content: "\e904";
}
.icon-arrow-up-left:before {
  content: "\e905";
}
.icon-mail:before {
  content: "\e906";
}
.icon-chat:before {
  content: "\e907";
}
.icon-map-pin:before {
  content: "\e908";
}
.icon-message-square:before {
  content: "\e909";
}
.icon-close:before {
  content: "\e90a";
}
.icon-copy:before {
  content: "\e90b";
}
.icon-clipboard:before {
  content: "\e90c";
}
.icon-edit:before {
  content: "\e90d";
}
.icon-flag:before {
  content: "\e90e";
}
.icon-inbox:before {
  content: "\e90f";
}
.icon-link:before {
  content: "\e910";
}
.icon-download:before {
  content: "\e911";
}
.icon-user-check:before {
  content: "\e912";
}
.icon-thermometer:before {
  content: "\e913";
}
.icon-canceled:before {
  content: "\e914";
}
.icon-file:before {
  content: "\e915";
}
.icon-cloud:before {
  content: "\e916";
}
.icon-folder:before {
  content: "\e917";
}
.icon-headphones:before {
  content: "\e918";
}
.icon-chevron-down:before {
  content: "\e919";
}
.icon-chevron-left:before {
  content: "\e91a";
}
.icon-chevron-right:before {
  content: "\e91b";
}
.icon-chevron-up:before {
  content: "\e91c";
}
.icon-square-round:before {
  content: "\e91d";
}
.icon-eye:before {
  content: "\e91e";
}
.icon-layers:before {
  content: "\e91f";
}
.icon-tag:before {
  content: "\e920";
}
.icon-package:before {
  content: "\e921";
}
.icon-arrow-left:before {
  content: "\e922";
}
.icon-arrow-right:before {
  content: "\e923";
}
.icon-check:before {
  content: "\e924";
}
.icon-menu:before {
  content: "\e925";
}
.icon-arrow-up:before {
  content: "\e926";
}
.icon-arrow-down:before {
  content: "\e927";
}
.icon-calendar-check:before {
  content: "\e928";
}
.icon-personal_video:before {
  content: "\e929";
}
.icon-users:before {
  content: "\e92a";
}
.icon-group_add:before {
  content: "\e92b";
}
.icon-user:before {
  content: "\e92c";
}
.icon-user-plus:before {
  content: "\e92d";
}
.icon-share-2:before {
  content: "\e92e";
}
.icon-thumbs-up:before {
  content: "\e92f";
}
.icon-refresh-ccw:before {
  content: "\e930";
}
.icon-check-circle:before {
  content: "\e931";
}
.icon-trash:before {
  content: "\e932";
}
.icon-file-text:before {
  content: "\e933";
}
.icon-heart:before {
  content: "\e934";
}
.icon-lock:before {
  content: "\e935";
}
.icon-info:before {
  content: "\e936";
}
.icon-external-link:before {
  content: "\e937";
}
.icon-file-plus:before {
  content: "\e938";
}
.icon-search:before {
  content: "\e939";
}
.icon-settings:before {
  content: "\e93a";
}
.icon-trend-up:before {
  content: "\e93b";
}
.icon-briefcase:before {
  content: "\e93c";
}
.icon-droplet:before {
  content: "\e93d";
}
.icon-clock:before {
  content: "\e93e";
}
.icon-home:before {
  content: "\e93f";
}
.icon-lightning:before {
  content: "\e940";
}
.icon-plus:before {
  content: "\e941";
}
.icon-monitor:before {
  content: "\e942";
}
.icon-female:before {
  content: "\e943";
}
.icon-activity:before {
  content: "\e944";
}
.icon-pregnant:before {
  content: "\e945";
}
.icon-compass:before {
  content: "\e946";
}
.icon-arrow-down-right:before {
  content: "\e947";
}
.icon-arrow-down-left:before {
  content: "\e948";
}
.icon-file-minus:before {
  content: "\e949";
}
.icon-male:before {
  content: "\e94a";
}
.icon-maximize:before {
  content: "\e94b";
}
.icon-minimize:before {
  content: "\e94c";
}
.icon-test-tube:before {
  content: "\e94d";
}
.icon-user-neg:before {
  content: "\e94e";
}
.icon-minus-square:before {
  content: "\e94f";
}
.icon-radiobutton-unchecked:before {
  content: "\e950";
}
.icon-checkbox-checked:before {
  content: "\e951";
}
.icon-checkbox-unchecked:before {
  content: "\e952";
}
.icon-radiobutton-checked:before {
  content: "\e953";
}
.icon-shipping:before {
  content: "\e954";
}
.icon-positive-circle:before {
  content: "\e955";
}
.icon-call:before {
  content: "\e956";
}
.icon-webpage:before {
  content: "\e957";
}
.icon-money:before {
  content: "\e958";
}
.icon-clipboard-check:before {
  content: "\e959";
}
.icon-creditcard:before {
  content: "\e95a";
}
.icon-gift:before {
  content: "\e95b";
}
.icon-insurance:before {
  content: "\e95c";
}
